import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OfirmieComponent } from './ofirmie/ofirmie.component';
import { KontaktComponent } from './kontakt/kontakt.component';
import { ProduktyComponent } from './produkty/produkty.component';
import { OfertaComponent } from './oferta/oferta.component';
import { PromocjeComponent } from './promocje/promocje.component';
import { NotfoundComponent } from './notfound/notfound.component';

import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    OfirmieComponent,
    KontaktComponent,
    ProduktyComponent,
    OfertaComponent,
    PromocjeComponent,
    NotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(
			[],
			{
				// Tell the router to use the hash instead of HTML5 pushstate.
				useHash: false,
 
				// In order to get anchor / fragment scrolling to work at all, we need to
				// enable it on the router.
				anchorScrolling: "enabled",
 
				// Once the above is enabled, the fragment link will only work on the
				// first click. This is because, by default, the Router ignores requests
				// to navigate to the SAME URL that is currently rendered. Unfortunately,
				// the fragment scrolling is powered by Navigation Events. As such, we
				// have to tell the Router to re-trigger the Navigation Events even if we
				// are navigating to the same URL.
				onSameUrlNavigation: "reload",
 
				// Let's enable tracing so that we can see the aforementioned Navigation
				// Events when the fragment is clicked.
				enableTracing: true,
				scrollPositionRestoration: "enabled"
			}
		)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
