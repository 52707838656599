import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OfirmieComponent} from './ofirmie/ofirmie.component';
import {KontaktComponent} from './kontakt/kontakt.component'
import { ProduktyComponent } from './produkty/produkty.component';
import { OfertaComponent } from './oferta/oferta.component';
import { PromocjeComponent } from './promocje/promocje.component';
import { NotfoundComponent } from './notfound/notfound.component';


const routes: Routes = [
  {
    path:'ofirmie',
    component:OfirmieComponent,
  }, 
  {
    path:'',
    component:PromocjeComponent,
  }, 
  {
    path:'oferta',
    component:OfertaComponent
  },
  {
    path:'kontakt',
    component:KontaktComponent
  },
  {
    path: 'produkty',
    component:ProduktyComponent
  },
  {
    path: 'promocje',
    component: PromocjeComponent
  }
  ,
  {
    path: '404',
    component: NotfoundComponent
  },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
